define("discourse/plugins/discourse-extra-group-classes/discourse/initializers/initialize-extra-group-classes", ["exports", "@ember/object", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _object, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Takes a list of classes like my-class1|my-class2
  // and returns an array of the form ["g-my-class1", "g-my-class2"]
  function parseClasses(classList) {
    let prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "g-";
    let separator = "|";
    return classList.split(separator).map(c => prefix + c);
  }
  var _default = _exports.default = {
    name: "extra-group-classes",
    initialize() {
      // decorate posts within topics
      (0, _pluginApi.withPluginApi)("0.11.4", api => {
        api.includePostAttributes("extra_classes");
        api.addPostClassesCallback(attrs => {
          if (attrs.extra_classes) {
            return parseClasses(attrs.extra_classes);
          }
        });
        api.modifyClass("component:user-card-contents", dt7948.p({
          extraClasses(user) {
            if (user && user.primary_group_extra_classes) {
              let classes = user.primary_group_extra_classes;
              classes = parseClasses(classes).join(" ");
              return classes;
            }
          },
          classNameBindings: ["extraClasses"]
        }, [["method", "extraClasses", [(0, _decorators.default)("user")]]]));
        api.modifyClass("controller:user", dt7948.p({
          primaryGroup() {
            let groupClasses = this._super(...arguments);
            if (this.model && this.model.primary_group_extra_classes) {
              let classes = this.model.primary_group_extra_classes;
              classes = parseClasses(classes).join(" ");
              groupClasses = `${groupClasses} ${classes}`;
            }
            return groupClasses;
          }
        }, [["method", "primaryGroup", [(0, _decorators.default)("model.primary_group_name")]]]));

        // decorate group posts
        api.modifyClass("component:group-post", {
          extraClasses: (0, _object.computed)(function () {
            if (this.post && this.post.extra_classes) {
              let classes = this.post.extra_classes;
              classes = parseClasses(classes).join(" ");
              return classes;
            }
          }),
          classNameBindings: ["extraClasses"]
        });

        // decorate extra classes on body of current user
        if (api.getCurrentUser()) {
          let user = api.getCurrentUser();
          if (user.primary_group_extra_classes) {
            let classes = parseClasses(user.primary_group_extra_classes, "primary-group-extra-");
            document.body.classList.add(...classes);
          }
        }

        // decorate participant lists
        api.addTopicParticipantClassesCallback(attrs => {
          if (attrs.primary_group_extra_classes) {
            return parseClasses(attrs.primary_group_extra_classes);
          }
        });
      });
    }
  };
});