define("discourse/plugins/discourse-extra-group-classes/discourse/templates/connectors/group-edit/extra-group-classes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{extra-group-classes-group-edit group=this.group}}
  */
  {
    "id": "8KcmF4Bw",
    "block": "[[[1,[28,[35,0],null,[[\"group\"],[[30,0,[\"group\"]]]]]]],[],false,[\"extra-group-classes-group-edit\"]]",
    "moduleName": "discourse/plugins/discourse-extra-group-classes/discourse/templates/connectors/group-edit/extra-group-classes.hbs",
    "isStrictMode": false
  });
});