define("discourse/plugins/discourse-extra-group-classes/discourse/templates/components/extra-group-classes-group-edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Only show these fields when editing an existing group }}
  {{#if this.group.id}}
  
    <div class="control-group">
      <label class="control-label">{{i18n "extra_group_classes.title"}}</label>
  
      <label for="extra_classes">
        {{i18n "extra_group_classes.description"}}
      </label>
      {{list-setting
        name="extra_classes"
        class="extra-classes"
        value=this.extraClasses
        choices=this.extraClasses
        settingName="name"
        nameProperty=null
        valueProperty=null
        onChange=(action "extraGroupClassesChanged")
        options=(hash allowAny=true)
      }}
    </div>
  {{/if}}
  */
  {
    "id": "kMQ6wPr8",
    "block": "[[[41,[30,0,[\"group\",\"id\"]],[[[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,1],[\"extra_group_classes.title\"],null]],[13],[1,\"\\n\\n    \"],[10,\"label\"],[14,\"for\",\"extra_classes\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"extra_group_classes.description\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"name\",\"class\",\"value\",\"choices\",\"settingName\",\"nameProperty\",\"valueProperty\",\"onChange\",\"options\"],[\"extra_classes\",\"extra-classes\",[30,0,[\"extraClasses\"]],[30,0,[\"extraClasses\"]],\"name\",null,null,[28,[37,3],[[30,0],\"extraGroupClassesChanged\"],null],[28,[37,4],null,[[\"allowAny\"],[true]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\",\"list-setting\",\"action\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-extra-group-classes/discourse/templates/components/extra-group-classes-group-edit.hbs",
    "isStrictMode": false
  });
});