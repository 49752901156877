define("discourse/plugins/discourse-extra-group-classes/discourse/components/extra-group-classes-group-edit", ["exports", "@ember/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse-i18n"], function (_exports, _component, _object, _service, _ajax, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ExtraGroupClassesGroupEdit extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    tokenSeparator = "|";
    get extraClasses() {
      if (this.group.extra_classes) {
        return this.group.extra_classes.split(this.tokenSeparator).filter(Boolean);
      }
      return null;
    }
    static #_2 = (() => dt7948.n(this.prototype, "extraClasses", [(0, _object.computed)("group.extra_classes")]))();
    extraGroupClassesChanged(value) {
      let newValue = value.join(this.tokenSeparator);
      let group = this.group;
      let oldValue = this.group.extra_classes;
      group.set("extra_classes", newValue);
      return (0, _ajax.ajax)(`/admin/groups/${group.id}/extra_classes`, {
        type: "PUT",
        data: group.getProperties("extra_classes")
      }).catch(() => {
        group.set("extra_classes", oldValue);
        this.dialog.alert(_discourseI18n.default.t("generic_error_with_reason", {
          error: _discourseI18n.default.t("extra_group_classes.save_error")
        }));
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "extraGroupClassesChanged", [_object.action]))();
  }
  _exports.default = ExtraGroupClassesGroupEdit;
});